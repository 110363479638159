<!--
  发布需求、发布需求
    + 一般机构
      + 只存在一个发布需求的表单
 -->
<template>
  <div class="detail">
    <detail-header title="发布需求" />
    <div class="detail_main page_width">
      <div class="form_content">
        <create-form
          ref="createForm">
        </create-form>
        <div class="btn_box">
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="createRequireBefore">
            发布需求
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createForm from '../components/require-create/create-form.vue'
import * as api from '@/api'

export default {
  components: {
    createForm
  },
  data () {
    return {
      // 提交按钮loading
      btnLoading: false
    }
  },
  methods: {
    // 获取到表单字段值
    async createRequireBefore () {
      let data = {}
      try {
        data = await this.$refs.createForm.validate()
        this.createRequirement(data)
      } catch (err) {
        console.log(err)
        return
      }
      console.log('进行需求的发布')
    },

    // 发布新需求
    createRequirement (data) {
      this.btnLoading = true
      api.createRequirement(data).then(res => {
        if (res.data.code === 0) {
          this.$message.success('发布需求成功！')
          this.$router.push({
            path: '/require-create-success',
            query: {
              serviceTypeId: data.serviceTypeId,
              title: data.title,
              content: data.description,
              keywords: data.keywords
            }
          })
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .form_content {
      width: 560px;
      margin: 0 auto;
      .btn_box {
        padding: 30px 0 0 180px;
      }
    }
  }
}
</style>
